.App {
  text-align: center;
  background-color: hsl(40, 23%, 97%);
}



.App-header {
  background-color: hsl(40, 23%, 97%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  place-items: center;
  
}

.App-link {
  color: #61dafb;
}

.card-holder{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75vmin;
  aspect-ratio: 5/7;
  
}

.gameCards{
  width: 70vmin;
  aspect-ratio: 5/7;
  border-radius: 2vmin;
  
}

.gameCards{
  position: inherit;
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Gaegu', cursive;
  font-size: 7vmin;
  color: hsl(42, 15%, 13%);
  text-align: center;
  width: 70vmin;
  aspect-ratio: 5/7;
  border-radius: 2vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.gameCards > p {
  margin-top: 35vmin;
  margin-left: 2vmin;
  margin-right: 2vmin;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  
}

.rules-card{
  position: inherit;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 3vmin;
  color: hsl(42, 15%, 13%);
  text-align: center;
  list-style-type: none;
  list-style-position: inside;
  border-radius: 2vmin;
  width: 70vmin;
  aspect-ratio: 5/7;
  
}

.rules-text{
  margin: 10px, 5px, 5px, 10px;
  padding: 1.5vmin, 0.5vmin, 0.5vmin, 0.5vmin;
  
}

ul {
  padding: inherit;
  line-height: 2;
  margin: inherit;
}

h5 {
  font-family: 'Nunito Sans', sans-serif;
  color: hsl(360, 67%, 44%);
  text-align: center;
  font-size: 6vmin;
  margin-bottom: 5vmin;
}

.newCard-button {
  margin-top: 110vmin;
  display: flex;
  justify-content: center;
  height: 8vmin;
  aspect-ratio: 1/1;
  color: hsl(360, 67%, 44%);
  font-size: 6vmin;
  border: hsl(40, 23%, 97%);
  background-color: hsl(40, 23%, 97%);
  align-items: center;
  
}

.hide-button {
  margin-top: 110vmin;
  display: flex;
  justify-content: center;
  height: 8vmin;
  aspect-ratio: 1/1;
  color: hsl(360, 67%, 44%);
  font-size: 6vmin;
  border: hsl(40, 23%, 97%);
  background-color: hsl(40, 23%, 97%);
  align-items: center;
  
}



/* font-family: 'Gaegu', cursive;
font-family: 'Nunito Sans', sans-serif; */